import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Switch, Table} from "antd";
import api from "../../service/api";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {FaCloudUploadAlt, FaEdit, FaTasks} from "react-icons/fa";
import Input from "antd/es/input/Input";
import {IoEnterOutline} from "react-icons/io5";
import {FaPlus} from "react-icons/fa6";
import toast from 'react-hot-toast'

const SubjectInnerView = () => {
    const {first_id, second_id} = useParams()
    const {user} = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const [subjects, setSubjects] = useState([])
    const [isOpen,setIsOpen] = useState(false)
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [temporaryData,setTemporaryData] = useState({})
    const [isOpenEdit,setIsOpenEdit] = useState(false)
    const [isOpenBBB,setIsOpenBBB] = useState(false)
    const navigate =useNavigate()
    const columns = [
            {
                title: '#',
                width: '3%',
                render: (_, record) => (
                    <div onClick={()=>showModalEdit(record)} className='w-full justify-center cursor-pointer'>
                        <FaEdit/>
                    </div>
                )

            },
            {
                title: 'Nomi',
                dataIndex: 'name',
                key: 'name',

            },
            {
                title: 'Holati',
               width: '5%',
                render:(_,record)=>(
                    <div>
                        <Switch className='bg-gray-500' checked={record.in_progress} onChange={()=>onChange(record)}  />
                    </div>
                )
            },
            {
                title: 'Resurs',
                width: '7%',
                render:(_,record)=>(
                    <div>
                        <Button onClick={()=>navigate(`/upload/resources/${record.id}`)} type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2' size={"large"}> <FaCloudUploadAlt /> Resurs </Button>
                    </div>
                )
            },

            {
                title: 'Topshiriqlar',
                key: 'action',
                width: "8%",
                render:(_,record)=>(
                    <div>
                        <Button onClick={()=>navigate(`/task/view/${record.id}`)} type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2' size={"large"}><FaTasks /> Topshiriqlar ({record?.count_tasks}) </Button>
                    </div>
                )
            },
        // {
        //     title: 'Dars xona',
        //     key: 'action',
        //     width: '9%',
        //     render:(_,record)=>(
        //         <div>
        //             {record.topic_bigbluebutton?
        //                 <Button disabled={!record.in_progress} onClick={()=>enterBBB(record.topic_bigbluebutton)}  className={record.in_progress?"bg-[#28C76F] w-full text-white font-bold  hover:bg-white  hover:text-[#28C76F] button-alian flex items-center justify-start gap-2":" flex items-center justify-center gap-2 "} size={"large"}> <IoEnterOutline />
        //                     Dars xonaga kirish </Button>
        //                :
        //                 <Button disabled={!record.in_progress}  className={record.in_progress?"bg-[#28C76F] w-full text-white font-bold  hover:bg-white  hover:text-[#28C76F] button-alian flex items-center justify-center gap-2":" flex items-center justify-center gap-2"} size={"large"} onClick={()=>showModalBBB(record.id)}> <FaPlus />
        //                     Dars xona yaratish </Button>
        //
        //             }
        //         </div>
        //     )
        // },
        ]
    ;
    const getSubjects = async () => {
        setLoading(true)
        await api.post("/teacher_topics/", {
            teacher_id: user?.employee_id_number,
            content_id_topic: first_id,
            content_teacher_connect: second_id
        }).then((res) => {
            console.log(res)
            setSubjects(res.data.result)
        }).catch((err) => {

        }).finally(() => {
            setLoading(false)
        })
    }
    const  enterBBB=(id)=> {
        api.get(`/teacher/bigbluebutton/check_session/?bbb_id=${id}`).then(() => {
            join(id)
        }).catch(() => {
            restart(id)
        })
    }
    const restart=(id)=> {
        api.get(`/teacher/bibluebutton/check_session/restart/?bbb_id=${id}&teacher_id=${user?.employee_id_number}`).then(() => {
          join(id)
        }).catch(() => {

        })
    }
    const join=(id)=> {
        api.post(`/bigbluebutton/join/moderator/`, {
            username: user?.name,
            id: id
        }).then((res) => {
            window.open(res.data.url)
        }).catch(() => {
            toast.success('Dars xona mavjud emas')
        })
    }
    const onChange = (record)=> {
        api.post(`/teacher_topic/active/`, {
            teacher_id: user?.employee_id_number,
            content_id_topic: first_id,
            content_teacher_connect: second_id,
            topic_id: record.id
        }).then(() => {
           getSubjects()
        }).catch((err)=>{
            toast.error('Xatolik yuz berdi')
        })
    }
    const showModal = ()=>{
        setIsOpen(prevState => !prevState)
    }
    const closeModal = ()=>{
        setIsOpen(prevState => !prevState)
    }
    const closeModalEdit = ()=>{
        setIsOpenEdit(prevState => !prevState)
    }
    const showModalEdit =date =>{
        setTemporaryData({
            id:date.id,
            title:date.name
        })
        formEdit.setFieldValue('title',date.name)
        setIsOpenEdit(prevState => !prevState)
    }
    const showModalBBB = (id)=>{
        setTemporaryData(id)
        setIsOpenBBB(prevState => !prevState)
    }
    const closeModalBBB = ()=>{
        setIsOpenBBB(prevState => !prevState)
    }
   const createTitle=(values)=> {
        api.post('/teacher_topic/add/', {
            content_id_topic: first_id,
            teacher_id: user?.employee_id_number,
            name:values.title,
            content_teacher_connect: second_id
        }).then((res) => {
            toast.success(<><p className='font-bold mr-2'>{values.title}</p> qo'shildi</>);
            closeModal()
            getSubjects()
            form.resetFields()
        }).catch((err)=>{
            toast.error('Xatolik yuz berdi')
        })

}
  const  createBBB=(values)=> {
        api.post(`/teacher/bigbluebutton/create/`, {
            name: values.name,
            maxParticipants: values.duration,
            teacher_id: user?.employee_id_number,
            topic_id:temporaryData
        }).then(() => {
           toast.success('Dars xona yaratildi')
           closeModalBBB()
            getSubjects()
        }).catch((err)=>{
            toast.error('Xatolik yuz berdi')
        })
    }
   const edit=(values)=> {
        api.put(`/teacher_topic/change/${temporaryData.id}/`,
            {
                name: values.title,
                content_teacher_connect: first_id,
                teacher_id: user?.employee_id_number
            }).then(() => {
            toast.success('Mavzu tahrirlandi!')
          getSubjects()
            closeModalEdit()
        }).catch((ere)=>{
            console.log(ere)
        })
    }
    useEffect(() => {
        getSubjects()
    }, [user])
    return (
        <div>

            <div className='flex  justify-between items-center'>
                <Button onClick={()=>navigate(-1)}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>
            <Button onClick={showModal}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size={"large"}> Mavzu yaratish </Button>
            </div>
            <Table pagination={false} loading={loading} columns={columns} dataSource={subjects}/>
            <Modal title="Mavzu qo'shish" centered open={isOpen} onCancel={closeModal} footer={null}>
                <Form
                onFinish={createTitle}
                form={form}
                >
                    <Form.Item
                        name="title"
                        rules={[
                        {
                            required: true,
                            message: "Iltimos to'ldiring!",
                        },
                    ]}>
                        <Input placeholder='Mavzu nomi'  size='large'/>
                    </Form.Item>
                    <Button htmlType={"submit"} type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3 w-full' size={"large"}>Yaratish</Button>
                </Form>
            </Modal>
            <Modal title="Mavzuni tahrirlash" centered open={isOpenEdit} onCancel={closeModalEdit} footer={null}>
                <Form
                    onFinish={edit}
                    form={formEdit}
                >
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos to'ldiring!",
                            },
                        ]}>
                        <Input placeholder='Mavzu nomi'  size='large'/>
                    </Form.Item>
                    <Button htmlType={"submit"} type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3 w-full' size={"large"}>Tahrirlash</Button>
                </Form>
            </Modal>
            <Modal title="Dars xona yaratish" centered open={isOpenBBB} onCancel={closeModalBBB} footer={null}>
                <Form
                    onFinish={createBBB}
                    form={formEdit}
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos to'ldiring!",
                            },
                        ]}>
                        <Input placeholder='Xona nomi'  size='large'/>
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos to'ldiring!",
                            },
                        ]}>
                        <Input placeholder='Max. qatnashuvchilar soni'  size='large'/>
                    </Form.Item>
                    <Button htmlType={"submit"} type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3 w-full' size={"large"}>Yaratish</Button>
                </Form>
            </Modal>
        </div>
    );
};

export default SubjectInnerView;