import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Table} from "antd";
import api from "../../service/api";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { FaTasks} from "react-icons/fa";
import Input from "antd/es/input/Input";
import toast from 'react-hot-toast'
import {IoEnterOutline} from "react-icons/io5";
import './subject.css'
import {FaPlus} from "react-icons/fa6";

const SubjectLessonInner = () => {
    const {first_id, second_id} = useParams()
    const {user} = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const [subjects, setSubjects] = useState([])
    const [formEdit] = Form.useForm();
    const [temporaryData, setTemporaryData] = useState({})
    const [isOpenBBB, setIsOpenBBB] = useState(false)
    const navigate = useNavigate()


    const columns = [

            {
                title: 'Nomi',
                dataIndex: 'name',
                key: 'name',

            },


            {
                title: 'Topshiriqlar',
                key: 'action',
                width: "8%",
                render: (_, record) => (
                    <div>
                        <Button onClick={() => navigate(`/task/view/for/mark/${record.id}`)} type='primary'
                                className='bg-[#1677ff] flex items-center justify-center gap-2'
                                size={"large"}><FaTasks/> Topshiriqlar </Button>
                    </div>
                )
            },
            // {
            //     title: 'Dars xonaga kirish',
            //     key: 'action',
            //     width: '9%',
            //     render: (_, record) => (
            //         <div>
            //             {record.topic_bigbluebutton ?
            //                 <Button disabled={!record.in_progress} onClick={() => enterBBB(record.topic_bigbluebutton)} className={record.in_progress?"bg-[#28C76F] w-full text-white font-bold  hover:bg-white  hover:text-[#28C76F] button-alian flex items-center justify-center gap-2":" flex items-center justify-center gap-2"} size={"large"}>
            //                     <IoEnterOutline/> Dars xonaga kirish </Button>
            //                 :
            //                 <Button disabled={!record.in_progress} className={record.in_progress?"bg-[#28C76F] w-full text-white font-bold  hover:bg-white  hover:text-[#28C76F] button-alian flex items-center justify-center gap-2":" flex items-center justify-center gap-2"}
            //                         size={"large"} onClick={() => showModalBBB(record.id)}> <FaPlus />
            //                     Dars xona
            //                     yaratish </Button>
            //
            //             }
            //         </div>
            //     )
            // },
            {
                title: 'Holat',
                width: '5%',
                render: (_, record) => (
                    <div>
                        <div className={record.in_progress ? 'active true' : 'active'}>
                        </div>
                    </div>
                )
            },
        ]
    ;
    const getSubjects = async () => {
        setLoading(true)
        await api.post("/teacher_topics/", {
            teacher_id: user?.employee_id_number,
            content_id_topic: first_id,
            content_teacher_connect: second_id
        }).then((res) => {
            console.log(res)
            setSubjects(res.data.result)
        }).catch((err) => {

        }).finally(() => {
            setLoading(false)
        })
    }
    const enterBBB = (id) => {
        api.get(`/teacher/bigbluebutton/check_session/?bbb_id=${id}`).then(() => {
            join(id)
        }).catch(() => {
            restart(id)
        })
    }
    const restart = (id) => {
        api.get(`/teacher/bibluebutton/check_session/restart/?bbb_id=${id}&teacher_id=${user?.employee_id_number}`).then(() => {
            join(id)
        }).catch(() => {

        })
    }
    const join = (id) => {
        api.post(`/bigbluebutton/join/moderator/`, {
            username: user?.name,
            id: id
        }).then((res) => {
            window.open(res.data.url)
        }).catch(() => {
            toast.success('Dars xona mavjud emas')
        })
    }


    const showModalBBB = (id) => {
        setTemporaryData(id)
        setIsOpenBBB(prevState => !prevState)
    }
    const closeModalBBB = () => {
        setIsOpenBBB(prevState => !prevState)
    }

    const createBBB = (values) => {
        api.post(`/teacher/bigbluebutton/create/`, {
            name: values.name,
            maxParticipants: values.duration,
            teacher_id: user?.employee_id_number,
            topic_id: temporaryData
        }).then(() => {
            toast.success('Dars xona yaratildi')
            closeModalBBB()
            getSubjects()
        })
    }

    useEffect(() => {
        getSubjects()
    }, [user])
    return (
        <div>
            <Button onClick={()=>navigate(-1)}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>

            <Table pagination={false} loading={loading} columns={columns} dataSource={subjects}/>
            <Modal title="Dars xona yaratish" centered open={isOpenBBB} onCancel={closeModalBBB} footer={null}>
                <Form
                    onFinish={createBBB}
                    form={formEdit}
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos to'ldiring!",
                            },
                        ]}>
                        <Input placeholder='Xona nomi' size='large'/>
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos to'ldiring!",
                            },
                        ]}>
                        <Input placeholder='Max. qatnashuvchilar soni' size='large'/>
                    </Form.Item>
                    <Button htmlType={"submit"} type='primary'
                            className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3 w-full'
                            size={"large"}>Yaratish</Button>
                </Form>
            </Modal>
        </div>
    );
};

export default SubjectLessonInner;