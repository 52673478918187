import React, {useEffect, useState} from 'react';
import {Button, Form} from "antd";
import Input from "antd/es/input/Input";
import {useNavigate} from "react-router-dom";


const Profile = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();


    useEffect(() => {
        form.setFieldsValue(JSON.parse(localStorage.getItem('user'))?.details)
    }, [])
    return (
        <div className=' w-full '>
            <Button onClick={()=>navigate(-1)}  type='primary' className=' w-fit bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>
            <Form
                disabled={true}
                layout='vertical'
                variant="filled"
                form={form}
                style={{
                    maxWidth: 500,
                    width:'100%'
                }}
            >
                <div className='title text-2xl font-bold'>Mening profilim</div>
                <div>

                </div>
                <Form.Item
                    label="Ism"
                    name="firstname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size={'large'}/>
                </Form.Item>
                <Form.Item
                    label="Familiya"
                    name="surname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size={'large'}/>
                </Form.Item>
                <Form.Item
                    label="Login"
                    name="login"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size={'large'}/>
                </Form.Item>
                <Form.Item
                    label="Passport raqami"
                    name="passport_number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size={'large'}/>
                </Form.Item>
                <Form.Item
                    label="Passport INN"
                    name="passport_pin"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size={'large'}/>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size={'large'}/>
                </Form.Item>
                <Form.Item
                    label="Telefon raqami"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size={'large'}/>
                </Form.Item>
            </Form>


        </div>
    );
};

export default Profile;